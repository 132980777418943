/**
 * @Name: 项目环境
 * @Descripttion:h5项目运行环境判断
 *  微信,微信小程序,钉钉,原生交互所需的SDK需要在本项目自己引入
 *  微信sdk: https://fs.iclass30.com/aliba/plug/weixin/weixin.js
 *  钉钉sdk: https://fs.iclass30.com/aliba/plug/dingtalk/dingtalk.open.js
 *  原生交互appcore sdk: https://fs.iclass30.com/aliba/plug/appcore/app_core.js
 *
 *   运行环境:
 *      钉钉
 *      微信
 *      微信小程序
 *      h5 (非微信,非钉钉,非原生嵌入)
 *   设备环境:
 *      手机端
 *          ios
 *          安卓
 *      电脑端
 * @Author: gaohan
 * @Date: 2022/7/9 13:40
 * @LastEditors: gaohan
 * @LastEditTime: 2022/7/9
 */
(function (factory) {
    "use strict";
    if (typeof exports === "object" && typeof module !== "undefined") {
        module.exports = factory();
    } else if (typeof define === "function" && define.amd) {
        define([], factory);
    } else {
        var g;
        if (typeof window !== "undefined") {
            g = window;
        } else if (typeof global !== "undefined") {
            g = global;
        } else if (typeof self !== "undefined") {
            g = self;
        } else {
            g = this;
        }
        g.mkEnv = factory()
    }
})(function () {
    "use strict";
    // 运行环境
    var RUNNING_TYPE = {
        // 钉钉环境
        dd: 'dd',
        // 微信环境
        wx: 'wx',
        // h5环境
        h5: 'h5',
        // 微信小程序
        miniprogram: 'miniprogram'
    }
    // 设备环境
    var DEVICE_TYPE = {
        // 手机端
        Phone: 'Phone',
        // 电脑端
        PC: 'PC',
        // 安卓端
        Android: 'Android',
        // Ios端
        Ios: "Ios"
    }

    var MKEnv = function(){
        // 版本号
        this.version = "0.0.1";
        // 是否手机端
        this.isMobile = false
        // 是否电脑端
        this.isPC = false
        // 是否ios
        this.isIos = false
        // 是否Android
        this.isAndroid = false
        // 是否钉钉
        this.isDD = false
        // 是否微信
        this.isWX = false
        // 是否纯H5(非微信,非钉钉,非原生嵌入)
        this.isPureH5 = true
        // 是否原生嵌入
        this.isAppcore = false
        // 设备环境
        this.deviceEnv = DEVICE_TYPE.Phone
        // 运行环境
        this.runningEnv = RUNNING_TYPE.h5

        /**
         * @name: 是否钉钉环境
         */
        this._isDD = function(){
            if( typeof dd === 'undefined'  ){
                return false
            }
            return dd.env && dd.env.platform !== "notInDingTalk"
        }
        /**
         * @name: 是否手机端
         */
        this._isMobile = function(){
            var u = navigator.userAgent;
            u = u.toLowerCase();
            var isIpad = /ipad/gi.test(u);
            var isIphoneOs = /iphone os/gi.test(u);
            var isMidp = /midp/gi.test(u);
            var isUc7 = /rv:1.2.3.4/gi.test(u);
            var isUc = /ucweb/gi.test(u);
            var isAndroid = /android/gi.test(u);
            var isCE = /windows ce/gi.test(u);
            var isWM = /windows mobile/gi.test(u);
            if (isIpad || isIphoneOs || isMidp || isUc7 || isUc || isAndroid || isCE || isWM) {
                return true;
            } else {
                return false;
            }
        }
        /**
         * @name: 是否微信环境
         */
        this._isWX = function(){
            return /micromessenger/gi.test(navigator.userAgent)
        }
        /**
         * @name: 是否原生嵌入环境
         */
        this._isAppcore = function(){
            return typeof window.WebViewJavascriptBridge !== "undefined" || typeof window.WVJBCallbacks !== "undefined"
        }
        /**
         * @name: 是否安卓
         */
        this._isAndroid = function(){
            return /Android|Adr/gi.test(navigator.userAgent)
        }
        /**
         * @name: 是否Ios
         */
        this._isIos = function(){
            return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        }
        /**
         * @name: 是否微信小程序
         */
        this._isMiniprogram = function(){
            return window.__wxjs_environment === 'miniprogram';
        }

        /**
         * @name: 环境初始化
         */
        this.initEnv = function(){
            this.isMobile = this._isMobile()
            this.isPC = !this.isMobile
            this.isDD = this._isDD()
            this.isWX = this._isWX()
            this.isPureH5 = !this.isDD && !this.isWX && !this._isAppcore()
            this.isAppcore = !this.isDD && !this.isWX && this._isAppcore()
            this.setEnv()
            this.setEvent()
        }
        /**
         * @name: 设置环境
         */
        this.setEnv = function(){
            this.deviceEnv = this.isPC ? DEVICE_TYPE.PC : DEVICE_TYPE.Phone

            if (this._isAndroid()) {
                this.isAndroid = true
                this.isIos = false
                this.deviceEnv = DEVICE_TYPE.Android
            } else if (this._isIos()) {
                this.isIos = true
                this.isAndroid = false
                this.deviceEnv = DEVICE_TYPE.Ios
            } else if (this._isMiniprogram()) {
                this.runningEnv = RUNNING_TYPE.miniprogram
                this.isIos = false
                this.isAndroid = false
            }else{
                this.isAndroid = false
                this.isIos = false
            }

            if( this.isDD ){
                this.runningEnv = RUNNING_TYPE.dd
            }else if( this.isWX ){
                this.runningEnv = this._isMiniprogram() ? RUNNING_TYPE.miniprogram : RUNNING_TYPE.wx
            }else{
                this.runningEnv = RUNNING_TYPE.h5
            }
        }
        /**
         * @name: 设置浏览器环境窗口监听事件
         */
        this.setEvent = function (){
            var self = this
            if( !self.isPureH5 ){
                return
            }
            var delta = 300;
            var debounce = function(fn, delay) {
                var timer;
                return function () {
                    if (timer) {
                        clearTimeout(timer);
                    }
                    timer = setTimeout(() => {
                        fn();
                    }, delay);
                };
            };
            window.onresize = debounce(self.initEnv.bind(self), delta);
            window.onload = debounce(self.initEnv.bind(self), delta)
        }
    }

    if (typeof mkEnv === "undefined") {
        var env = new MKEnv()
        env.initEnv()
        return env
    } else {
        console.error("mkEnv is already defined.");
        return mkEnv
    }


});

